.banner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 800px;
    background: linear-gradient(108.69deg, #232D89 8.27%, #3672C6 35.39%, #2896D3 65.19%, #286CD3 97.39%);

    .back {
        font-size: 17px;

        svg {
            width: 18px;
            height: auto;
        }
    }

    &__title {
        font-family: Open Sans Condensed;
        font-style: normal;
        font-weight: 300;
        font-size: 64px;
        line-height: 171.5%;
        text-align: center;
        color: #FFFFFF;
    }

    &__left {
        left: 0;
        top: 0;
        position: absolute;
        pointer-events: none;

        img {
            display: block;
        }
    }

    &__right {
        position: absolute;
        right: 0;
        top: 0;
        pointer-events: none;

        img {
            display: block;
        }
    }

    &__bottom {
        position: absolute;
        right: 0;
        bottom: 0;
        pointer-events: none;

        img {
            display: block;
        }
    }
}