.logo-list {
    padding-top: 25px;
    padding-bottom: 34px;


    &__wrap {
        margin: 0 -5px -13px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    .container {
        width: 1310px;
    }

    &__item {
        display: inline-flex;
        margin: 0 5px 13px;

        &:last-child {
            margin-right: -35px;
        }
    }
}