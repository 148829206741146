.news {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px -16px;

    &__item {
        display: flex;
        flex-direction: column;
        border: 1px solid #FFFFFF;
        width: calc(~"33.3% - 16px");
        margin: 0 8px 16px;
        text-decoration: none;
        background-color: #3182CC;

        &:hover {
            .news {
                &__img {
                    opacity: 0.9;
                }
            }
        }
    }

    &__text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.04em;
        color: #F2F2F2;
        margin-bottom: 12px;
    }

    &__name {
        font-family: Montserrat;
        font-size: 17px;
        line-height: 130%;
        letter-spacing: -0.04em;
        color: #FFFFFF;
        margin-bottom: 8px;
    }

    &__bottom {
        padding: 8px 8px 12px 8px;
    }

    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    }

    &__type {
        display: flex;
        align-items: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -.04em;
        color: #f2f2f2;
        margin-top: -2px;

        svg {
            margin-right: 11px;
        }
    }

    &__date {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.04em;
        color: #F2F2F2;
    }

    &__img {
        width: 100%;
        height: 150px;
        background-position: 50%;
        background-size: cover;
        position: relative;
        transition: opacity 0.3s ease;

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}