* {
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

a, button {
    outline: 0;
}

a {
    color: @darkblue;
    transition: color 0.3s ease;
}


p {
    margin: 0;
    font-size: 16px;
    line-height: 200.5%;
    letter-spacing: -0.01em;
    color: #000000;
}

body {
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
   
    &.is-blue {
       .wrapper {
            overflow: hidden;
            min-height: 100vh;
            position: relative;
            background: linear-gradient(108.69deg, #232D89 8.27%, #3672C6 35.39%, #2896D3 65.19%, #286CD3 97.39%);
      
            &::before {
                content:" ";
                position: absolute;
                pointer-events: none;
                width: 992px;
                height: 664px;
                right: -130px;
                bottom: -78px;
                background-image: url(../images/common/back-right.png);
                background-size: cover;
                background-position: bottom center;
        
            }

            &::after {
                content:" ";
                position: absolute;
                pointer-events: none;
                width: 992px;
                height: 664px;
                left: -331px;
                bottom: -372px;
                background-image: url(../images/common/back-left.png);
                background-size: cover;
                background-position: bottom center;
        
            }
        }
    }


    &.is-inner {
        background-image: url(../images/common/back-light.png);
        background-position: top;
        background-repeat: no-repeat;
        background-size: 100% auto;


        h1 {
            font-weight: 500;
            font-size: 17px;
            line-height: 171.5%;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            color: #000000;
            margin-bottom: 30px; 
        }

        .wrapper {
            padding-top: 20px;

            &__aside {
                width: 301px;
            }

            &__content {
                padding-top: 0;
            }

            &__inner {
                padding-top: 0;
                width: calc(~'100% - 301px');
                padding-left: 20px;
            }
        }
    }
}