.menu {
    margin-right: 63px;
    margin-top: 6px;
    
    a {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.04em;
        text-decoration: none;
        color: #FFFFFF;
        margin-left: 24px;
        font-family: 'Montserrat', sans-serif;
        border-bottom: 1px solid transparent;
        transition: border-color 0.3s ease;

        &:hover {
            border-color: #fff;
        }

        &:first-child {
            margin-left: 0;
        }
    }
}

@media(max-width: 1271px) {
    .menu {
        margin-top: 25px;
        margin-right: 0;
    }
}

@media(max-width:  992px) {
    .menu {
        margin-right: 63px;
        margin-top: 0;
    }
}

@media(max-width: 768px) {
    .menu { 
        a {
            font-size: 15px;
            line-height: 20px;
            margin-left: 14px;
        }
    }
}