.ruk {
    display: flex;

    &__avatar {
        flex: none;
        width: 70px;
        height: 70px;
        background-color: #C4C4C4;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
    }

    &__name {
        font-size: 17px;
        line-height: 162.5%;
        letter-spacing: 0.01em;
        color: #000000;
        margin-bottom: 3px;
    }

    &__text {
        font-size: 12px;
        line-height: 128.5%;
        letter-spacing: 0.01em;
        color: #000000;
    }

    &__body {
        padding-left: 15px;
    }

    &--light {
        flex-direction: column;

        .ruk {
            &__avatar {
                width: 100%;
                border-radius: 0;
                height: 124px;
                margin-bottom: 10px;
            }

            &__text {
                font-size: 16px;
                line-height: 128.5%;
            }

            &__name {
                font-size: 22px;
                line-height: 162.5%;
            }

            &__body {
                padding-left: 0;
            }
        }
    }
}