.catalog {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -14px -73px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Montserrat;
        padding: 15px;
        margin: 0 14px 73px;
        width: calc(~"33.3% - 28px");
        height: 198px;
        background-color: #163E73;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 10px;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        letter-spacing: -0.04em;
        text-decoration: underline;
        color: #FFFFFF;
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 0.8;
        }
    }
}

@media(max-width:  992px) {
    .catalog {
        margin-bottom: -28px;

        &__item {
            margin-bottom: 28px;
            height: 19vw;
            font-size: 21px;
            line-height: 29px;
        }
    }
}

@media(max-width: 768px) {
    .catalog {
        margin: 0 -8px -16px;

        &__item {
            font-size: 18px;
            line-height: 23px;
            margin: 0 8px 16px;
            width: calc(~"33.3% - 16px");
        }
    }
}

@media(max-width: 560px) {
    .catalog {
        &__item {
            height: 24vw;
            margin: 0 8px 16px;
            width: calc(~"50% - 16px");
        }
    }
}

@media(max-width: 400px) {
    .catalog {
        &__item {
            height: 32vw;
            width: calc(~"100% - 16px");
        }
    }
}