.text-block {

    padding: 25px 30px;

    background-color: #EAF5FA;
    border-radius: 20px;

    &--light {
        padding: 0;
        background-color: transparent;
        border-radius: 0;
    }

    ol {
        margin: 25px 0;
        counter-reset: myCounter;
        padding: 0;

        li {
            position: relative;
            list-style: none;
            font-size: 16px;
            line-height: 200.5%;
            letter-spacing: -.01em;
            color: #000;
            padding-left: 25px;
            margin-bottom: 12px;

            &::before {
                position: absolute;
                left: 0;
                top: 6px;
                counter-increment: myCounter;
                content: counter(myCounter);
                color: #1da3de;
                display: inline-block;
                text-align: center;
                margin: 0;
                line-height: 1;
                width: 22px;
                text-align: center;
                font-size: 18px;
            }
        }
    }
}