.link-block {
    display: flex;
    flex-direction: column;

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: #fff;
        padding: 5px;
        margin-bottom: 20px;
        transition: opacity 0.3s ease;

        img {
            max-width: 100%;
            max-height: 100%;
        }

        &:last-child {
            margin-bottom: 0;
        }

     /*   &:nth-child(1) {
            opacity: 0.5;
        }

        &:nth-child(2) {
            opacity: 0.7;
        }

        &:nth-child(3) {
            opacity: 0.8;
        }

        &:nth-child(6) {
            opacity: 0.8;
        }

        &:nth-child(7) {
            opacity: 0.7;
        }

        &:nth-child(8) {
            opacity: 0.5;
        }

        &:nth-child(9) {
            opacity: 0.3;
        }

        &:nth-child(10) {
            opacity: 0.3;
        }*/

        &:hover {
            opacity: 0.8;
        }
    }
}

@media(max-width:  992px) {
    .link-block {
        margin: 0 -5px 10px;
        flex-direction: row;
        flex-wrap: wrap;
        
        &__item {
            width: calc(~"20% - 10px");
            margin: 0 5px 10px;
            height: 52px;
            opacity: 1 !important;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

@media(max-width: 560px) {
    .link-block {

        &__item {
            width: calc(~"50% - 10px");
        }
    }
}