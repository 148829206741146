.project {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px -16px;

    &__link {
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        a {
            display: inline-flex;
            font-family: Montserrat;
            font-style: italic;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.04em;
            text-decoration-line: underline;
            color: #F2F2F2;
            margin-bottom: 8px;
            transition: opacity 0.3s ease;

            &:hover {
                opacity: 0.8;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__text {
        margin-top: 8px;
        font-family: Montserrat;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.04em;
        color: #F2F2F2;
    }

    &__item {
        display: flex;
        flex-direction: column;
        border: 1px solid #FFFFFF;
        width: calc(~"33.3% - 16px");
        margin: 0 8px 16px;
        text-decoration: none;
        background-color: #3182CC;
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 0.7;
            .project {
                &__btn {
                    .btn {
                        background-color: #fff;
                        color: #2990D4;
                    }
                }
            }
        }
    }

    &__h {
        width: 100%;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 37px;
        letter-spacing: -.04em;
        margin: 35px 0 35px;
        color: #fff;
    }
    
    &__btn {
        margin-top: 12px;
    }

    &__name {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 17px;
        line-height: 120%;
        color: #fff;
    }


    &__bottom {
        display: flex;
        flex-direction: column;
        padding: 8px 10px 12px 10px;
    }

    &__type {
        display: flex;
        align-items: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -.04em;
        color: #f2f2f2;
        margin-top: 7px;

        svg {
            flex: none;
            margin-right: 11px;
        }
    }


    &__img {
        width: 100%;
        height: 150px;
        background-position: 50%;
        background-size: cover;
        position: relative;
        transition: opacity 0.3s ease;

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }

    &--center {
        .project {
            &__type {
                margin-top: 0;
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                letter-spacing: -0.04em;
            }

            &__item {
                &:hover {
                    opacity: 1;
                }
            }

            &__img {
                &::before { 
                    background-color: rgba(0,0,0,.3);
                }
            }
        }
    }

    &--catalog {
        .project {
            &__item {
                width: calc(~"50% - 16px");
            }

            &__img {
                height: 262px;

                &::before {
                    display: none;
                }
            }
        }
    }

    &--mission {
        .project {
            &__type {
                position: relative;
                padding: 0 10px 12px;
            }

            &__img {
                display: flex;
                align-items: flex-end;
            }
        }
    }
    

    &--big {
       

        .project {
            &__img {
                width: calc(~"50% - 16px");
                margin: 0 8px;
                height: 262px;

                &-wrap {
                    display: flex;
                    margin: 0 -8px;
                }

                &::before {
                    display: none;
                }
            }

            &__item {
                width: calc(~"100% - 16px");
            }
        }
    }
}

@media(max-width: 1250px) {
    .project {
        &--big {
            .project {
                &__img {
                    height: 20vw;
                }
            }
        }
    }
}

@media(max-width: 768px) {
    .project {
        &__item {
            width: calc(~"50% - 16px");
        }
    }
}

@media(max-width: 480px) {
    .project {
        &__item {
            width: calc(~"100% - 16px");
        }

        &--big {
            .project {
                &__img {
                    height: 48vw;
                    width: 100%;
                    margin: 0;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &-wrap {
                        display: block;
                        margin: 0;
                    }
                }
            }
        }

        &__img {
            height: 49vw;
        }
    }
}