.contact {
    display: flex;
    flex-wrap: wrap;
    margin: 50px -20px -40px;

    &__item {
        display: flex;
        width: calc(~"50% - 40px");
        margin: 0 20px 40px;
        color: #FFFFFF;
        font-family: Montserrat;
        font-size: 14px;
        line-height: 150.7%;
        letter-spacing: -0.04em;
    }

    &__post {
        font-family: Montserrat;
        font-size: 14px;
        line-height: 150.7%;
        letter-spacing: -0.04em;
       
    }

    &__main  {
        font-family: Montserrat;
        font-weight: 600;
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.04em;
        color: #fff;
        margin-bottom: 8px;
    }

    &__row {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__name {
        margin-bottom: 13px;
    }

    &__avatar {
        width: 130px;
        height: 130px;
        border-radius: 50%;
        flex: none;
        background-position: center;
        background-size: cover;
    }

    &__body {
        padding-left: 20px;
    }
}

@media(max-width: 705px) {
    .contact {
        &__item {
            width: calc(~"100% - 40px");
        }
    }
}

@media(max-width: 420px) {
    .contact {
        &__item {
            display: block;
        }

        &__body {
            padding-left: 0;
        }

        &__avatar {
            margin-bottom: 15px;
        }
    }
}