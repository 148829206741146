.aside {
    &__item {
        background-color: #EAF5FA;
        border-radius: 20px;
        padding: 15px 20px;
        margin-bottom: 22px;
        font-size: 17px;
        line-height: 128.5%;
        letter-spacing: 0.01em;
        color: #000000;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font-weight: bold;
        font-size: 17px;
        line-height: 162.5%;
        letter-spacing: 0.01em;
        color: #000000;
        margin-bottom: 10px;
    }

    &__wrap {
        &--text {
            text-align: center;

            img {
                display: block;
                margin: 0 auto 10px;
            }
        }
    }

    &--light {
        .aside {
            &__item {
                font-size: 16px;
                line-height: 128.5%;
                background-color: transparent;
                padding: 0;
                margin-bottom: 30px;
            }

            &__title {
                font-weight: normal;
                padding: 6px 20px;
                display: flex;
                align-items: center;
                min-height: 40px;
                background-color: #1DA3DE;
                margin-bottom: 20px;
                color: #fff;
            }
        }
    }
}