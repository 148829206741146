.header {
    padding-top: 49px;
    padding-bottom: 41px;

    &__top {
        display: flex;
        justify-content: space-between;
        padding-right: 56px;
    }

    &__title {
        margin: 0;
        font-weight: bold;
        font-size: 28px;
        line-height: 33px;
        color: #FFFFFF;

        a {
            margin: 0;
            font-weight: bold;
            font-size: 28px;
            line-height: 33px;
            color: #FFFFFF;text-decoration: none;   
        }
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
        margin-left: 28px;
        flex: none;

        &--column {
            align-items: flex-end;
            flex-direction: column;

            .lang {
                order: 1;
            }

            .menu {
                order: 2;
                margin-right: 0;
                margin-top: 23px;
            }
        }
    }
}

@media(max-width: 1271px) {
    .header {
        &__info {
            flex-direction: column;
            align-items: flex-end;

            .menu {
                order: 2;
            }

            .lang {
                order: 1;
            }
        }
    }
}


@media(max-width:  992px) {
    .header {
        padding-top: 25px;
        
        &__top {
            flex-direction: column;
            padding-right: 0;
        }

        &__info {

            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-top: 16px;
            margin-left: 0;

            .menu {
                order: 1;
            }

            .lang {
                order: 2;
                display: none;
            }
        }
    }
}

@media(max-width: 768px) {
    .header {
        &__title {
            font-size: 25px;
            line-height: 1.2;
        }
    }
}