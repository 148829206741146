
.container {
    width: 1271px;
    max-width: 100%;
    padding: 0 15px;
    margin: 0 auto;

    &--row {
        display: flex;
    }
}

h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 37px;
    letter-spacing: -0.04em;
    margin: 0 0 35px;
    color: #FFFFFF;
}

.back {
    display: inline-flex;
    font-weight: bold;
    font-size: 22px;
    line-height: 171.5%;
    align-items: center;
    letter-spacing: 0.01em;
    color: #1DA3DE;
    transition: opacity 0.3s ease;
    text-decoration: none;
    margin-bottom: 30px;


    &:hover {
        opacity: 0.8;
    }

    svg {

        margin-right: 10px;
    }
}