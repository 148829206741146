.doc {
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__btn {
        flex: none;

        .btn {
            padding-left: 23px;
            padding-right: 23px;
        }
    }

    &__body {
        max-width: 80%;
    }

    &__type {
        font-family: Montserrat;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.04em;
        color: #F2F2F2;
        margin-bottom: 10px;
    }

    &__name {
        font-family: Montserrat;
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: -0.04em;
        color: #FFFFFF;
    }
}