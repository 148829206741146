.filter {
    display: flex;
    margin-bottom: 43px;
    display: none;

    &__item {
        width: 239px;
        margin-right: 33px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__title {
        font-family: Montserrat;
        font-size: 17px;
        line-height: 150.7%;
        letter-spacing: -0.04em;
        color: #FFFFFF;
    }
}

@media(max-width: 480px) {
    .filter {
        display: block;
        display: none;

        &__item {
            width: 100%;
            margin: 0 0 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}