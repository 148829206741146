.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    font-size: 12px;
    line-height: 15px;
    padding: 7px 16px;
    outline: 0;
    text-decoration: none;
    letter-spacing: -0.04em;
    transition: border-color 0.3s, color 0.3s, background-color 0.3s ease;

    &--blue {
        border: 1px solid #2990D4;
        background-color: #2990D4;
        color: #F2F2F2;

        &:hover {
            background-color: #F2F2F2; 
            color:  #2990D4;
            border: 1px solid #2990D4;
        }
    }

    &--big {
        font-size: 16px;
        line-height: 17px;
        padding: 12px 20px;
        font-weight: 700;
    }

    &--out-white {
        background-color: #2990D4;
        border: 1px solid #fff;
        color: #F2F2F2;

        &:hover {
            background-color: #fff;
            color: #2990D4;
        }
    }
}