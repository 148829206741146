.select {

    .jq-selectbox.focused .jq-selectbox__select {
        border: 1px solid #fff;
    }

    .jq-selectbox {
        width: 100%;
    }

    .jq-selectbox__dropdown {
        border: 1px solid #fff;
        border-radius: 0;
        margin: 0;
        width: 100% !important;
    }

    .jq-selectbox__dropdown li {
        padding: 3px 20px;
        font-size: 17px;
        line-height: 150.7%;
        letter-spacing: -.04em;
        white-space: pre-wrap;

        &.sel {
            color: #fff;
            background: #0767B0;
        }

        &:hover {
            color: #fff;
            background: #0767B0;
        }
    }

    .jq-selectbox__select {
        height: 33px;
        box-shadow: none;
        border-radius: 0;
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        padding-left: 20px;
        outline: 0;
        background: #0767B0;
    }

    .jq-selectbox__trigger-arrow {
        border: 0;
    }

    .jq-selectbox__trigger {
        border: 0;
        background-image: url(../images/common/select.svg);
        background-position: center;
        background-repeat: no-repeat;
    }

    .jq-selectbox__select-text {
        font-size: 17px;
        line-height: 150.7%;
        letter-spacing: -.04em;
        color: #f2f2f2;
        text-shadow: none;
    }
}