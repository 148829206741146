.wrapper {
    padding-bottom: 50px;

    &--pb0 {
        padding-bottom: 0;
    }

    &__inner {
        width: calc(~"100% - 188px");
        padding-left: 57px;
        padding-top: 11px;
        position: relative;
    }

    &__body {
        position: relative;
        z-index: 2;
    }

    &__aside {
        width: 188px;
        flex: none;
    }

    &__content {
        padding-top: 24px;
    }

    &--light {
        .wrapper {
            &__aside {
                width: 280px !important;
            }

            &__inner {
                width: calc(~"100% - 280px") !important;
                padding-left: 60px !important;
            }
        }
    }
}

@media(max-width:  992px) {
    .wrapper {
        .container {
            &--row {
                flex-direction: column;
            }
        }

        &__content {
            padding-top: 0;
        }

        &__aside {
            width: 100%;
        }

        &__inner {
            width: 100%;
            padding-left: 0;
        }
    }
}