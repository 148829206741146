.lang {
    display: flex;
    align-items: center;
    font-size: 22px;
    line-height: 130.5%;
    color: #FFFFFF;

    span {
        margin-right: 4px;
    }
}
