.tag {
    padding-left: 0;
    margin-bottom: 5px;

    &__wrap {
        display: flex;
        flex-wrap: wrap;
    }
  

    a {
        display: inline-flex;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 1;
        letter-spacing: -0.04em;
        color: #FFFFFF;
        border-bottom: 1px solid #fff;
        text-decoration: none;
        margin-right: 27px;
        margin-bottom: 16px;

        &:last-child {
            margin-right: 0;
        }
    }
}

@media(max-width: 992px) {
    .tag {
        display: none;
    }
}